.gridWrapper {
  //padding: 0 24px;
  max-width: 1440px;
  margin: 0 auto;
}

@media screen and (min-width: 1024px) {
  .gridWrapper {
    //padding: 0 40px;
  }
}

@media screen and (min-width: 1440px) {
  .gridWrapper {
    padding: 0;
  }
}
